import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import { navigate } from '@reach/router';

import BookingForm from '../../components/booking-form';
import Footer from '../../components/footer';
import Layout from '../../components/layout';
import MansionMap from '../../components/mansion-map';
import NavSection from '../../components/nav-section';

export class MansionPage extends React.Component {
  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
    
    const footer = get(this, 'props.data.allContentfulFooter.edges')

    const bookingForm = get(this, 'props.data.allContentfulBookingForm.edges')[0].node;
    return (
      <Layout color="lightBrown">
        <NavSection navMenus={navMenus} navTheme="nav-white" />
        <Helmet title="Visit the Palacio" />
        <div className="container">
          <div className="gallery-header">
            <h1>Visit the Palacio</h1>
          </div>
        </div>

        <div className="container pt-2 clearfix map-image-container">
          <BackToMap />
          <MansionMap />
        </div>
        <BookingForm bookingForm={bookingForm}/>
        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

function BackToMap() {
  const backToMap = e => {
    navigate(`/venues`);
  };

  return (
    <div className="back-to-map">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 72.1 87"
        version="1.1"
        viewBox="0 0 72.1 87"
        xmlSpace="preserve"
        onClick={e => backToMap(e)}
      >
        <text className="st0 st1 st2" transform="matrix(1.1 0 0 1 1.5 84)">
          BACK TO MAP
        </text>
        <path d="M20.3 24.8s33.6-3.5 30 30.7" className="st4"></path>
        <path d="M27.5 32.7L19.5 24.7 27.5 16.7" className="st4"></path>
        <path d="M2 1.2H70V67.2H2z" className="st4 path-bg"></path>
      </svg>
    </div>
  );
}

export default MansionPage;

export const pageQuery = graphql`
  query MansionPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulBookingForm {
      edges {
        node {
          header
          firstNameInputPlaceholder
          lastNameInputPlaceholder
          contactNoInputPlaceholder
          emailInputPlaceholder
          bookingTypeInputPlaceholder {
            name
            value
          }
          dateInputPlaceholder
          timeInputPlaceholder
          venueInputPlaceholder {
            name
            value
            selected_value
          }
          messageInputPlaceholder
          newsletterInputPlaceholder
          submitInputPlaceholder
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
